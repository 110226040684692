import { render, staticRenderFns } from "./collection.vue?vue&type=template&id=49a670bb&scoped=true"
import script from "./collection.vue?vue&type=script&lang=js"
export * from "./collection.vue?vue&type=script&lang=js"
import style0 from "@/utils/style/public-style.css?vue&type=style&index=0&id=49a670bb&prod&scoped=true&lang=css&external"
import style1 from "./collection.vue?vue&type=style&index=1&id=49a670bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a670bb",
  null
  
)

export default component.exports