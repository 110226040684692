<!-- 消息管理 -->
<template>
  <div id="message">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>采集设备信息管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="设备名称">
              <el-input clearable v-model="requestParam.cameraName" placeholder="请输入设备名称" class="custom-input"></el-input>
            </el-form-item>
            <el-form-item label="场站">
              <el-select size="small" filterable :popper-append-to-body="false" clearable class="custom-select"
                v-model="requestParam.stationNumber" placeholder="请选择场站">
                <el-option v-for="item in stationList" :key="item.id" :label="item.stationName+' ' +item.stationNumber"
                  :value="item.stationNumber"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采集设备类型">
              <el-select :popper-append-to-body="false" v-model="requestParam.deviceType" placeholder="请选择消息状态"
                clearable>
                <el-option v-for="item in collectionType" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"></el-option>
               
              </el-select>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button :class="isStringInArray('btnNewCollection') ? '' : 'btnShowAuthority'" type="primary" icon="el-icon-plus" @click="updateDialog('',1)">新建设备</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>
                {{ (requestParam.page - 1) * requestParam.pageSize +
                  scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="stationNumber" label="场站编码">
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="场站名称">
          </el-table-column>
          <el-table-column align="center" prop="cameraName" label="采集设备名称">
          </el-table-column>
          <el-table-column align="center" prop="deviceTypeNameDict" label="采集设备类型">
          </el-table-column>
          <el-table-column align="center" prop="ip" label="IP地址">
          </el-table-column>
          <el-table-column align="center" prop="gatawayUrl" label="网关地址">
          </el-table-column>
          <el-table-column align="center" prop="subnetMask" label="子网掩码">
          </el-table-column>
          <el-table-column align="center" prop="state" label="状态">
            <template slot-scope="scope">
              {{ scope.row.state==0? '在线':scope.row.state==1?'离线':'-' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="resp" label="视频流地址">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注">
          </el-table-column>

          <el-table-column align="center" label="操作" prop="operate" fixed="right" width="200">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="updateDialog(scope.row,3)">详情</el-button>
                <span :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'" type="text" @click="updateDialog(scope.row,2)">编辑</el-button>
                <span :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'" type="text" @click="updateDialog(scope.row,4)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.page" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialogType==1?'新增':dialogType==2?'修改':'详情'" :visible.sync="dialogVisible" width="840px" @close="dialogClose">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="detailsData" :inline="true" :model="detailsData" :rules="rules">

          <el-form-item label="变配电站" prop="stationNumber">
            <el-input class="stationName" placeholder="请选择变配电站" v-model="stationName" :readonly="true">
              <template slot="append" v-if="dialogType !=3">
                <span @click="selectStation">选择配电站</span>
              </template>
            </el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="采集设备类型"  prop="deviceType">
              <el-select @change="typeChange" :disabled="dialogType==3" :popper-append-to-body="false" v-model="detailsData.deviceType" placeholder="请选择消息状态"
                clearable>
                <el-option v-for="item in collectionType" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="采集设备名称">
            <el-input :disabled="dialogType==3" clearable v-model="detailsData.cameraName" placeholder="请输入设备名称" class="custom-input"></el-input>
            </el-form-item>
          <el-form-item label="采集设备型号">
            <el-input :disabled="dialogType==3" clearable v-model="detailsData.model" placeholder="请输入采集设备型号" class="custom-input"></el-input>
            </el-form-item>
            <el-form-item label="在线状态"  v-if="detailsData.deviceType=='2'" prop="state">
              <el-select :disabled="dialogType==3" :popper-append-to-body="false" v-model="detailsData.state" placeholder="请选择消息状态"
                clearable>
                <el-option label="离线" :value="1"></el-option>
                <el-option label="在线" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <div class=""></div>
          <el-form-item label="IP地址" prop="ipAddress">
            <el-input :disabled="dialogType==3" clearable v-model="ipParts[0]" @input="updateIP"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="ipParts[1]" @input="updateIP" class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="ipParts[2]" @input="updateIP"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="ipParts[3]"  @input="updateIP" class="custom-input w100"></el-input>
            </el-form-item>
          <el-form-item label="网关地址" prop="gatawayUrlAddress">
            <el-input :disabled="dialogType==3" clearable v-model="gatawayUrlParts[0]" @input="updateGataway"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="gatawayUrlParts[1]" @input="updateGataway"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="gatawayUrlParts[2]" @input="updateGataway"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="gatawayUrlParts[3]" @input="updateGataway"  class="custom-input w100"></el-input>
            </el-form-item>
          <el-form-item label="子网掩码" prop="subnetMaskAddress">
            <el-input :disabled="dialogType==3" clearable v-model="subnetMaskParts[0]" @input="updatesubnetMask"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="subnetMaskParts[1]" @input="updatesubnetMask"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="subnetMaskParts[2]" @input="updatesubnetMask"  class="custom-input w100"></el-input>
            <span style="padding: 0 10px;">.</span>
            <el-input :disabled="dialogType==3" clearable v-model="subnetMaskParts[3]" @input="updatesubnetMask"  class="custom-input w100"></el-input>
            </el-form-item>
          <el-form-item label="视频流地址" v-if="detailsData.deviceType=='2'" prop="resp">
            <el-input :disabled="dialogType==3" v-model="detailsData.resp"  type="textarea" :rows="3"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input :disabled="dialogType==3" v-model="detailsData.remark"  type="textarea" :rows="3"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" v-if="dialogType !=3">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="collectionAdd('detailsData')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="centerDialogVisible1" width="20%">
      <div class="deleteCenter">
        确定删除该条信息?
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
    <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="centerDialogVisible1 = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="centerDelete">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
// import { messageApi } from "@/api/message.js";
import { alarmApi } from "@/api/alarm.js";
import stationDialog from "../operationManagement/commponents/stationDialog.vue";
import {
  getStationList,
} from "@/api/testTask.js";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      tableData: [
      ],
      requestParam: {
        page: 1,
        pageSize: 10,
        deviceType:null,
        stationNumber:null,
        cameraName:null,
      },
      total: 0,
      inputValue: "1",
      id: "",
      loading: false,
      dialogVisible: false,
      collectionType:[],
      ipParts: ['', '', '', ''],
      gatawayUrlParts: ['', '', '', ''],
      subnetMaskParts: ['', '', '', ''],
      detailsData: {
        stationNumber:'',//场站编码
        deviceType:'',//采集设备类型
        cameraName:'',//设备名称
        model:'',//采集设备型号
        resp:'',//视频流
        remark:'',//备注
        state:'',//是否在线
        
        ipAddress:'',
        gatawayUrlAddress:'',
        subnetMaskAddress:'',
      },
      stationList: [],
      stationId: 0,
      dialogStatus:false,
      stationName:'',
      dialogType:1,
      centerDialogVisible1:false,
      rules: {
        stationNumber: [
          { required: true, message: "请选择场站名称", trigger: "change" },
        ],
        deviceType: [
          { required: true, message: "请选择采集设备类型", trigger: "change" },
        ],
        state: [
          { required: true, message: "请选择在线状态", trigger: "change" },
        ],
        resp: [
          { required: true, message: "请输入视频流地址", trigger: "blur" },
        ],
       
        ipAddress: [
          { required: true, message: '请输入完整的IP地址', trigger: 'blur' },
          { validator: this.validateIP, trigger: 'blur' }
        ],
        gatawayUrlAddress: [
          { required: true, message: '请输入完整的网关地址', trigger: 'blur' },
          { validator: this.validategataway, trigger: 'blur' }
        ],
        subnetMaskAddress: [
          { required: true, message: '请输入完整的子网掩码', trigger: 'blur' },
          { validator: this.validatesubnetMask, trigger: 'blur' }
        ],

      },
      authorityListBtn:[]
    };
  },

  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "采集设备信息管理");
    this.$store.commit("selectChildren", "");
    // this.requestParam.stationNumber = sessionStorage.getItem("stationNumber");
  },
  mounted() {
    this.onsubmitStation()
    this.queryMessage();
    this.searchDictCodeList()
    this.authorityListBtn = sessionStorage.getItem('roleInfo');


  },
  methods: {
        //按钮权限
        isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    updateGataway() {
      this.detailsData.gatawayUrlAddress = this.gatawayUrlParts.join('.');
    },
    updatesubnetMask() {
      this.detailsData.subnetMaskAddress = this.subnetMaskParts.join('.');
    },
    updateIP() {
      this.detailsData.ipAddress = this.ipParts.join('.');
    },
    validateIP(rule, value, callback) {
      const segments = value.split('.').filter(item => item !== '');
      console.log('~~~~~~~',segments);
      if (segments.length === 4 && segments.every(segment => segment >= 0 && segment <= 255)) {
        callback();
      } else {
        callback(new Error('请输入有效的IP地址'));
      }
    },
    validategataway(rule, value, callback) {
      const segments = value.split('.').filter(item => item !== '');
      console.log('~~~~~~~',segments);
      if (segments.length === 4 && segments.every(segment => segment >= 0 && segment <= 255)) {
        callback();
      } else {
        callback(new Error('请输入有效的网关地址'));
      }
    },
    validatesubnetMask(rule, value, callback) {
      const segments = value.split('.').filter(item => item !== '');
      console.log('~~~~~~~',segments);
      if (segments.length === 4 && segments.every(segment => segment >= 0 && segment <= 255)) {
        callback();
      } else {
        callback(new Error('请输入有效的子网掩码'));
      }
    },
    //删除
    centerDelete(){
      alarmApi.deleteCollection(this.id).then(res=>{
        console.log(res);
        if(res.code==200){
            this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.centerDialogVisible1=false
              this.queryMessage();
          }else{
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
      })

    },
    typeChange(val){
      if(this.detailsData.deviceType !='2'){ //如果不是摄像头就清空视频流跟状态
        this.detailsData.resp= null
        this.detailsData.state=3
      }
    },
    //新增/修改
    collectionAdd(form){
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log("this.detailsData",this.detailsData);
      // stationNumber:'',//场站编码
      //   deviceType:'',//采集设备类型
      //   cameraName:'',//设备名称
      //   model:'',//采集设备型号
      if(this.detailsData.deviceType !='2'){ //如果不是摄像头就清空视频流跟状态
        this.detailsData.resp=null  
        this.detailsData.state=3
      }
      if(this.dialogType==1){

        let objAdd={
          // ...this.detailsData,
          stationNumber:this.detailsData.stationNumber,
          deviceType:this.detailsData.deviceType,
          cameraName:this.detailsData.cameraName,
          model:this.detailsData.model,
          resp:this.detailsData.resp,
          remark:this.detailsData.remark,
          state:this.detailsData.state,
         
          ip:this.detailsData.ipAddress,
          gatawayUrl:this.detailsData.gatawayUrlAddress,
          subnetMask:this.detailsData.subnetMaskAddress,
        }
        
        console.log(this.detailsData);
        alarmApi.addCollection(objAdd).then(res=>{
          console.log(res);
          if(res.code==200){
            this.$message({
                message: res.data,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.dialogVisible=false
              this.queryMessage();
          }else{
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
        })
      }else{
        let objEdit={
          // ...this.detailsData,
          id:this.id,
          stationNumber:this.detailsData.stationNumber,
          deviceType:this.detailsData.deviceType,
          cameraName:this.detailsData.cameraName,
          model:this.detailsData.model,
          resp:this.detailsData.resp,
          remark:this.detailsData.remark,
          state:this.detailsData.state,
          ip:this.detailsData.ipAddress,
          subnetMask:this.detailsData.subnetMaskAddress,
          gatawayUrl:this.detailsData.gatawayUrlAddress,
        }
       
        console.log(this.detailsData);
        alarmApi.editCollection(objEdit).then(res=>{
          console.log(res);
          if(res.code==200){
            this.$message({
                message: res.data,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.dialogVisible=false
              this.queryMessage();
          }else{
            this.$message({
              message: res.message,
              type: 'error',
              duration: 3000,
              customClass: "messageText",
            });
          }
        })

      }
    } else {
          return false
        }
      });
    },
    //采集设备类型
    searchDictCodeList(){
      alarmApi.searchDictCodeList({dictCode:'device_type'}).then(res=>{
        this.collectionType=res.data
      })
    },
    // 选择场站
    getStationName(value) {
      console.log(value);
      if (value.id) {
        // this.deviceData.stationId = value.id;
        this.detailsData.stationNumber = value.stationNumber;
        this.stationName = value.stationName;
        // this.queryCircuitName();
        // this.deviceData.loopIdList=''
        this.dialogStatus = false;
      }
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    //获取场站下拉框
    onsubmitStation() {
      getStationList().then(res => {
        if (res.code == 200) {
          this.stationList = res.data
        }
      })
    },
    formQuery() {
      this.requestParam.page = 1;
      this.queryMessage();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.page = 1;
      this.queryMessage();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.page = lastPage;
      this.queryMessage();
    },
    confirm() {
      this.requestParam.page = this.inputValue;
      this.queryMessage();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryMessage();
    },
    changeCurrent(page) {
      this.requestParam.page = page;
      this.queryMessage();
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.createdAtEnd = null;
        }
      }
    },
    updateDialog(data,type) {
      this.id = data.id;
      this.dialogType=type
      if(type !=1&&type!=4){
        alarmApi.getCollDetails( this.id).then(res=>{
          console.log(res);
          this.stationName=res.data.stationName
          this.detailsData.stationNumber=res.data.stationNumber
          this.detailsData.deviceType=res.data.deviceType
          this.detailsData.cameraName=res.data.cameraName
          this.detailsData.model=res.data.model
          this.detailsData.resp=res.data.resp
          this.detailsData.remark=res.data.remark
          this.detailsData.state=res.data.state
          if(res.data.ip){
           this.ipParts = res.data.ip.split('.');
           this.updateIP()
            // console.log(ipArray);
            // this.detailsData.ip1=ipArray[0]
            // this.detailsData.ip2=ipArray[1]
            // this.detailsData.ip3=ipArray[2]
            // this.detailsData.ip4=ipArray[3]
          }
          if(res.data.gatawayUrl){
            this.gatawayUrlParts = res.data.gatawayUrl.split('.');
            this.updateGataway()
            // console.log(ipArray);
            // this.detailsData.gatawayUrl1=ipArray[0]
            // this.detailsData.gatawayUrl2=ipArray[1]
            // this.detailsData.gatawayUrl3=ipArray[2]
            // this.detailsData.gatawayUrl4=ipArray[3]
          }
          if(res.data.subnetMask){
            this.subnetMaskParts = res.data.subnetMask.split('.');
            this.updatesubnetMask()
            // console.log(ipArray);
            // this.detailsData.subnetMask1=ipArray[0]
            // this.detailsData.subnetMask2=ipArray[1]
            // this.detailsData.subnetMask3=ipArray[2]
            // this.detailsData.subnetMask4=ipArray[3]
          }
          if(this.detailsData.deviceType !='2'){ //如果不是摄像头就清空视频流跟状态
        this.detailsData.resp=null  
        this.detailsData.state=''
      }
        })
      }
      if(type==4){
        this.centerDialogVisible1=true
        
      }else{
        this.dialogVisible = true;
        this.$refs.detailsData.clearValidate();

      }
      // this.detailsData = data;
      // this.detailsData.messageFlag =
      //   this.detailsData.messageFlag === 1 ? "已读" : "未读";
      // this.detailsData.messageScope =
      //   this.detailsData.messageScope === 1 ? "PC" : "移动";
    },
    dialogClose() {
      this.detailsData= {
        stationNumber:'',//场站编码
        deviceType:null,//采集设备类型
        cameraName:'',//设备名称
        model:'',//采集设备型号
        resp:'',//视频流
        remark:'',//备注
        state:'',
        ipAddress:'',
        gatawayUrlAddress:'',
        subnetMaskAddress:'',
      }
      this.stationName=''
      this.ipParts= ['', '', '', '']
      this.gatawayUrlParts= ['', '', '', '']
      this.subnetMaskParts= ['', '', '', '']
      this.$refs.detailsData.clearValidate();
    },
    queryMessage() {
      this.loading = true;
      alarmApi.collectionList(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
   
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#message {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* .el-link.el-link--default{
color: #aed6ff;
}
.el-link.is-underline:hover:after{
  border-bottom: 1px solid #aed6ff;
} */
/* 执行结果 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.dialog-form :deep().el-form-item__label {
  width: 100px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.dialog-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.dialog-form :deep() .el-form-item {
  margin: 0 0 16px 0;
}

.dialog-form :deep() .el-input {
  width: 257px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 630px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.form>>>.el-form-item__label {
  width: 100px;
}
.stationName >>>.el-input__inner {
  width: 300px !important;
}
.stationName >>>.el-input-group__append {
  background: #143168;
    color: #fff;
    border: 0;
}
.w100{
  width: 100px !important;
}
.deleteCenter {
  font-size: 16px;
  height: 80px;
  line-height: 80px;
  padding-left: 20px;
  color: #EBEDF1;
}
</style>
